<template>
  <div id="app" class="mt-5">
    <br />
    <br />
    <br />
    <br />
    <br />
    <data-table
      :headers="headers"
      :data="sales"
      :buttons="actionButtons"
    ></data-table>
    <!-- <sale-data-table></sale-data-table> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from "../components/cards/DataTable.vue";
// import SaleDataTable from "../components/cards/SaleDataTable.vue";

export default {
  name: "App",
  components: {
    DataTable,
    // SaleDataTable,
  },
  data() {
    return {
      headers: [
        { label: "Référence", field: "reference" },
        { label: "Client", field: "customerFullName" },
        { label: "totalTTC", field: "totalPriceTTC" },
        { label: "Quantite", field: "totalQuantity" },
        { label: "Impaie", field: "amountPaid" },
        { label: "Creer par", field: "userName" },
        { label: "Statut", field: "validated" },
      ],
      
      actionButtons: [
        {
          label: 'Modifier',
          class: 'border border-secondary',
          handler: this.openEditor
        },
        {
          label: 'Supprimer',
          class: 'border border-secondary',
          handler: this.deleteRow
        },
        {
          label: 'Afficher',
          class: 'border border-secondary',
          handler: this.showDetails
        }
      ]
    };
  },
  methods:{
    openEditor(row) {
      this.$router.push({name:'sales-edit',params:{reference:'zzzzz'}})
    },
    deleteRow(row) {
      // Handle delete action
    },
    showDetails(row) {
      // Handle details action
    }
  },
  computed: {
    ...mapGetters("sale", {
      sales: "getAll",
      totalQuantity: "getSalesTotalQuantity",
      totalPriceTTC: "getSalesTotalPriceTTC",
      totalPaid: "getSalesTotalPaid",
      totalUnpaid: "getSalesTotalUnpaid",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  created() {
    this.$store.dispatch("customer/getAll");
    this.$store.dispatch("sale/fetchSales");
  },
};
</script>
